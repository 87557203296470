* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.hidden {
  display: none !important;
}

html {
  width: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6fdfd;
}

.main { /* Container that encloses the navigation bar and main content area*/
  min-height: calc(100vh - 2.72em); /* -2.72em to leave space for navigation bar*/
}

button:hover {
  cursor: pointer;
}

.navigation-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #167e70;
  border-bottom: 3px solid #192e31;
  min-height: 80px;
}

.navigation-title-container {
  display:flex;
  align-items: center;
}
.navigation-title-container > h1 {
  color: #192e31;
}

.navigation-menu-components {
  display: flex;
  flex-direction: row;
}

.navigation-menu-components > * {
  margin-left: 20px;
  margin-right: 20px;
}

.navigation-button-container {
  color: #192e31;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
}

.navigation-button-container:hover {
  color: #020908;
}

.active > .navigation-button-container {
  color: #020908;
}

.navigation-hamburger-menu-container > *{
  display: none;
}

a:link { text-decoration: none; } /* Disable all link underlines */

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a:active { text-decoration: none; }

.wrapper { /* the main content div */
  margin-inline: auto;
  width: 700px;
  padding-top: 20px;
  padding-bottom: 50px;
}

.title-container { /* title container right below navigation bar*/
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #167e70;
}

.mode-prompt {
  font-size: 16px;
  font-weight: 600;
  font-style: oblique;
  color: #020908;
}

.mode-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.mode-button-container > button {
  background-color: #12685d;
  box-shadow: 2px 2px 2px #192e31;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  min-height: 40px;
  margin-left: 4px;
  margin-right: 4px;
}

.mode-button-container > button:hover {
  background-color: #192e31;
}

.active-mode {
  background-color: #192e31 !important;
}

.form-container {
  width: 100%;
}

.input-container {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.input-radios {
  width: 50%;
  padding-right: 2%;
}

.input-radio-option {
  margin-bottom: 10px;
  color: #192e31;
  font-weight: 500;
}

#compounding-period-container {
  height: auto !important;
}

#payment-period-container {
  height: auto !important;
}

#payment-timing-container {
  height: auto !important;
}

.input-labels {
  color: #020908;
  display: flex;
  align-items: flex-start;
  padding-left: 2%;
  font-size: 16px;
  font-weight: bold;
}

.input-fields {
  border: none;
  margin-right: 2%;
  border-radius: 6px;
  width: 50%;
  background-color: #f3f7fc;
  box-shadow: inset -4px -4px 6px 0 hsla(0, 0%, 100%, 0.522), inset 4px 4px 5px 0 rgba(67, 82, 104, 0.2);
}

.input-fields:focus {
  outline: none;
  color: #192e31;
}

.submit-button-container {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  text-align: center;
}

#submit-button {
  color: white;
  border: none;
  background-color: #244247;
  box-shadow: 2px 2px 3px #000000;
  width: 50%;
  height: 100%;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

#submit-button:hover {
  cursor: pointer;
}

footer {
  text-align: center;
  color: #020908;
  font-weight: bold;
  font-size: 12px;
  border-top: 2px solid #020908;
  background-color: #244247;
  height: 3.5em;
}

@media only screen and (max-width: 950px) {
  .navigation-button-container {
    font-size: 14px;
  }

  .navigation-menu-components > * {
    margin-left: 15px;
    margin-right: 15px;
  }

  .navigation-title-container {
    font-size: 14px;
  }

}

@media only screen and (max-width: 820px) {
  .navigation-button-container {
    font-size: 12px;
  }

  .navigation-menu-components > * {
    margin-left: 10px;
    margin-right: 10px;
  }

  .navigation-title-container {
    font-size: 12px;
  }

  .wrapper {
    width: 85vw;
  }

}

@media only screen and (max-width: 700px) {

  .navigation-hamburger-menu-container > *{
    display: block;
  } 

  .navigation-menu-components {
    display: none;
  }

}

@media only screen and (max-width: 480px) {
  .title {
    font-size: 18px;
  }

  .mode-prompt {
    font-size: 12px;
  }

  .mode-button-container > button {
    font-size: 10px;
  }

  .input-labels {
    font-size: 12px;
  }

  #submit-button {
    font-size: 16px;
  }

  .input-radio-option > * {
    font-size: 12px;
  }

  .navigation-title-container > * {
    font-size: 20px;
  }

}

.overlay-container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-button-container {
  margin-top: 3vh;
  margin-bottom: 3vh;
  color: #192e31;
  font-weight: bold;
  font-size: 16px;
}

.overlay-button-container:hover {
  color: #167e70;
}


.active > .overlay-button-container {
  color: #167e70;
}


.close-button-container > button {
  background-color: transparent;
  border: none;
  color: #192e31;
  font-size: 30px;
  font-weight: bold;
  position: fixed;
  top: 10px;
  right: 10px;
}

.hamburger-menu-button {
  background-color: transparent;
  border: none;
  color: #192e31;
  font-size: 36px;
  font-weight: bold;
  height: 100%;
  width: 100%;
}